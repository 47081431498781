import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import { capFirst, debounce, getPictureSource, ready } from '#js/components/utils'
import algoliasearch from 'algoliasearch/lite'
import { fetchJSON } from '#js/components/http'
import { filterItems } from '#js/components/algoliaUtils'
import { gettext } from '#js/components/i18n'
import insightsClient from 'search-insights'
import suggestionsPlugin from '#js/components/autocompleteSuggestion'

const AUTOCOMPLETE_SELECTOR = '#autocomplete'
const HITS_PER_PAGE = 10
const MAX_GROUP_ITEMS = 3

export const itemsMapping = {
  expert: {
    header: capFirst(gettext('counselling')),
    isRounded: true
  },
  host: {
    header: capFirst(gettext('host')),
    isRounded: true
  },
  offerlist: {
    header: capFirst(gettext('selected offers')),
    isRounded: false
  },
  topic: {
    header: capFirst(gettext('topics')),
    isRounded: false
  },
  targetgroup: {
    header: capFirst(gettext('target groups')),
    isRounded: false
  },
  default: {
    header: capFirst(gettext('offers')),
    isRounded: false
  }
}

const debounced = debounce(async (items) => await items)

let filters = null

export async function getFilters () {
  if (filters) {
    return filters
  }

  const settings = await fetchJSON('/api/search-settings/')

  if (!settings) {
    console.warn('Failed to fetch autocomplete filters.')
    return null
  }

  filters = settings.filters
  return filters
}

export function getInitialState () {
  return {
    query: new URLSearchParams(location.search).get('query') || ''
  }
}

export function onSubmit ({ state }) {
  if (!state.query) {
    location.href = '/search'
  } else {
    location.href = `/search?query=${state.query}`
  }
}

export function onSelect ({ item }) {
  location.href = item.public_url
}

export async function getItems ({ query }) {
  const searchClient = algoliasearch(window.algoliaAppId, window.algoliaAPIKey)

  const filters = await getFilters()

  if (!filters) {
    return []
  }

  return getAlgoliaResults({
    searchClient,
    queries: [
      {
        indexName: window.language === 'en' ? 'event_en' : 'event',
        query,
        params: {
          hitsPerPage: HITS_PER_PAGE,
          filters,
          distinct: true
        }
      }
    ]
  })
}

export function item ({ item, html, components }) {
  const sources = Object.entries(item.picture.sources).map(
    ([mimeType, sizes]) => ({
      type: mimeType,
      srcset: getPictureSource(sizes),
      sizes: item.picture.media
    })
  )
  const highlightedTitle = components.Highlight({ hit: item, attribute: 'title' })
  const highlightedParentObjectTitle = components.Highlight({ hit: item, attribute: 'parent_object_title' })
  const itemKey = item.objectID.split('-')[0]
  const isRounded = itemsMapping[itemKey]?.isRounded

  return html`
    <div class="row" style="gap: var(--space)">
      <picture class="aa-ItemIcon--picture ${isRounded && 'aa-ItemIcon--picture-rounded'}">
        ${sources.map(
          (source) =>
            html`<source type="${source.type}" srcset="${source.srcset}" sizes="${source.sizes}"></source>`
        )}
        <img
          src="${item.picture.url}"
          alt="${item.parent_object_title}"
        />
      </picture>
      <div class="aa-ItemContentDescription">
        <strong>${item.title ? highlightedTitle : highlightedParentObjectTitle}</strong>
        <br />
        <span>${item.title && highlightedParentObjectTitle}</span>
      </div>
    </div>
  `
}

export function getGroupSource ({ name, items }) {
  return {
    getItems: () => items.slice(0, MAX_GROUP_ITEMS),
    templates: {
      header ({ html }) {
        return (
          html`
            <span className="aa-SourceHeaderTitle" style="color: var(--brand-color)">
              ${name}
            </span>
            <div className="aa-SourceHeaderLine" />
          `
        )
      }
    }
  }
}

export function groupByCategory (baseSource) {
  const items = filterItems(baseSource.getItems())

  // Create multiple groups based on the first item's category.
  // Example: { 'Counselling': [item1, item2], 'Offers': [item3, item4] }
  const groupedItems = items.reduce((accumulator, item) => {
    const itemKey = item.objectID.split('-')[0]
    const key = itemsMapping[itemKey]?.header || itemsMapping.default.header

    if (!Object.prototype.hasOwnProperty.call(accumulator, key)) {
      accumulator[key] = []
    }

    accumulator[key].push(item)
    return accumulator
  }, {})

  // Create a source for each group and override the templates to add a header.
  return Object.entries(groupedItems).map(([groupName, groupItems]) => {
    const source = getGroupSource({ name: groupName, items: groupItems })

    return {
      ...baseSource, // Add the default source properties
      sourceId: groupName, // Use the group name as the sourceId, example: 'Counselling' or 'Offers'
      getItems () {
        return groupItems
      },
      ...source, // Add the new group properties
      templates: {
        ...((baseSource).templates), // Add the default templates
        ...(source).templates // Add the new group templates
      }
    }
  })
}

export const productsPlugin = {
  getSources ({ query }) {
    if (!query) {
      return []
    }
    return debounced([
      {
        sourceId: 'productsSource',
        onSelect,
        getItems,
        templates: {
          item
        }
      }
    ])
  }
}

export const showMorePlugin = {
  getSources () {
    return [
      {
        sourceId: 'showMoreSource',
        getItems ({ state }) {
          return [
            {
              label: gettext('show all'),
              url: `/search?query=${state.query}`
            }
          ]
        },
        getItemUrl ({ item }) {
          return item.url
        },
        templates: {
          item ({ html, item }) {
            return html`
              <a href="${item.url}" style="text-align: center;">
                ${item.label}
              </a>
            `
          }
        }
      }
    ]
  }
}

export const noResultsPlugin = {
  getSources () {
    return [
      {
        sourceId: 'noResultsSource',
        getItems () {
          return [
            {
              label: gettext('Do you need inspiration? Browse through our vast portfolio!'),
              url: '/search'
            }
          ]
        },
        getItemUrl ({ item }) {
          return item.url
        },
        templates: {
          item ({ html, item }) {
            return html`<a href="${item.url}">${item.label}</a>`
          }
        }
      }
    ]
  }
}

export function reshape ({ sourcesBySourceId, state }) {
  // These are the sources that we want to display in the autocomplete.
  const { suggestionsSource, productsSource, showMoreSource, noResultsSource, ...rest } = sourcesBySourceId
  const result = [suggestionsSource]

  if (productsSource) {
    const groups = groupByCategory(productsSource)

    if (groups.length > 0) {
      result.push(groups)
      result.push(showMoreSource)
    }

    if (state.query && groups.length === 0) {
      result.push(noResultsSource)
    }
  }
  result.push(...Object.values(rest))
  return result
}

export function initAutocomplete () {
  if (!document.querySelector(AUTOCOMPLETE_SELECTOR)) {
    return
  }

  insightsClient('init', {
    appId: window.algoliaAppId,
    apiKey: window.algoliaAPIKey,
    authenticatedUserToken: window.userId
  })

  const algoliaAutocomplete = autocomplete({
    container: AUTOCOMPLETE_SELECTOR,
    insights: true,
    placeholder: capFirst(gettext('search')),
    translations: {
      detachedCancelButtonText: gettext('exit')
    },
    openOnFocus: true,
    plugins: [
      suggestionsPlugin,
      productsPlugin,
      showMorePlugin,
      noResultsPlugin
    ],
    initialState: getInitialState(),
    onSubmit,
    reshape
  })

  const trigger = document.querySelector('[data-algolia="autocomplete-trigger"]')
  trigger?.addEventListener('click', () => {
    algoliaAutocomplete.setIsOpen(true)
  })
}

ready(function () {
  initAutocomplete()
})
