import { ready } from '#js/components/utils'

const utmPassThroughHosts = [
  /\.?voiio\.app$/,
  /^localhost(:\d+)?$/
]
const utmPassThroughParams = [
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'promotion_id',
  'promotion_name',
  'item_list_id',
  'item_list_name'
]

export function passThroughUtmParams (windowUrl) {
  let utmQueryParams = utmPassThroughParams.map(param => [param, windowUrl.searchParams.get(param)])
  utmQueryParams = utmQueryParams.filter(value => value[1] !== null)
  let links = document.querySelectorAll('a:not([data-ignore-pass-through="all"])')
  links = Array.from(links).filter(link => link.href)

  links.forEach(link => {
    if (typeof link.href !== 'string') return
    // SVGs have link.href as an object, not a string. Ignore them.
    const url = new URL(link.href)
    if (utmPassThroughHosts.some(re => re.test(url.host))) {
      utmQueryParams.forEach(
        param => {
          if (
            !(
              (link.dataset.ignorePassThrough === 'utm' && param[0].includes('utm_')) ||
              (link.dataset.ignorePassThrough === 'promotion' && param[0].includes('promotion_'))
            )
          ) {
            url.searchParams.set(...param)
          }
        }
      )
      link.href = url.toString()
    }
  })

  Array.from(document.forms).filter(form => form.action).forEach(form => {
    const url = new URL(form.action)
    if (utmPassThroughHosts.some(re => re.test(url.host))) {
      utmQueryParams.forEach(param => url.searchParams.set(...param))
      form.action = url.toString()
    }
  })
}

/**
 * Add tracking parameters to a path and create a new URL object.
 * @param {string} path - The path to add tracking parameters to.
 * @returns {URL} - A new URL object with tracking parameters.
 */
export function addTrackingUrlParameters (path) {
  const url = new URL(path, window.location.origin)
  const params = new URLSearchParams(window.location.search)
  params.forEach((value, key) => {
    if (utmPassThroughParams.includes(key)) {
      url.searchParams.append(key, value)
    }
  }
  )

  return url
}

/**
 * Add tracking parameters to a URL.
 * @param {string} url - The URL to add tracking parameters to.
 * @param {object} trackingInformation - The tracking information to add to the URL.
 * @returns {string} - The URL with tracking parameters.
 */
export function getUrlWithTracking (url, trackingInformation) {
  const trackingUrl = new URL(addTrackingUrlParameters(url))
  Object.entries(trackingInformation).forEach(([key, value]) => {
    if (value !== null && value !== undefined && value !== '' && utmPassThroughParams.includes(key)) {
      trackingUrl.searchParams.set(key, value)
    }
  })
  return trackingUrl.toString()
}

ready(function () {
  passThroughUtmParams(new URL(window.location.href))
})
