import { LitElement } from 'lit'

export class TrackingMixin extends LitElement {
  static get properties () {
    return {
      promotionName: { type: String },
      promotionId: { type: String },
      itemListId: { type: String },
      itemListName: { type: String }
    }
  }

  trackingInformation = {}

  connectedCallback () {
    super.connectedCallback()
    this.trackingInformation = {
      promotion_id: this.promotionId,
      promotion_name: this.promotionName,
      item_list_id: this.itemListId,
      item_list_name: this.itemListName
    }
  }
}
