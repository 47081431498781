import { LitElement, html } from 'lit'
import { createRef, ref } from 'lit/directives/ref.js'
import { msg, str, updateWhenLocaleChanges } from '@lit/localize'
import { fetchJSON } from '#js/components/http'
import { gtag } from '#js/components/utils'
import { setLocale } from '#js/components/lit-i18n'

export class OfferSlider extends LitElement {
  static get properties () {
    return {
      id: { type: String },
      apiUrl: { type: String },
      title: { type: String },
      listUrl: { type: String },
      listTitle: { type: String }
    }
  }

  constructor () {
    super()
    setLocale(window.language)
    updateWhenLocaleChanges(this)
  }

  sliderRef = createRef()
  offers = undefined

  async firstUpdated () {
    this.offers = await this.fetchOffer()
    this.requestUpdate()
  }

  render () {
    return html`
      <link rel="stylesheet" href="${window.styleFilePath}"/>
      <section>
        <h2>${this.title}</h2>
        <div class="layout">
          <div class="slider-grid" ${ref(this.sliderRef)}>
            ${this.offers ? [...this.renderOffers(this.offers, this.id, this.title)] : this.renderPlaceholder()}
          </div>
          ${this.getListUrlLink()}
        </div>
      </section>
    `
  }

  * renderOffers (offers, promotionId, promotionName) {
    for (let i = 0; i < 4; i++) {
      yield html`
          <offer-slider-card .offer="${offers[i]}"
                             sliderPosition="${i}"
                             promotionId="${promotionId}"
                             promotionName="${promotionName}"
          >
          </offer-slider-card>
        `
    }
  }

  renderPlaceholder () {
    return html`
      ${[...Array(4)].map(i => {
        return html`
          <div class="card card--offer">
            <div class="card__image card__image--offer placeholder"
                 style="border-radius: 0">
              <div class="ribbon__wrapper">
                <div class="ribbon">${msg(str`loading`)}&hellip;</div>
              </div>
            </div>
            <div class="card__content">
              <h3 class="placeholder" style="height: var(--space--double);">
                &#8203;</h3>
              <p class="placeholder">&#8203;</p>
              <div class="bubble__wrapper" style="justify-content: space-between;">
                <div class="bubble bubble--small placeholder"
                     style="width: 4rem;">&#8203;
                </div>
                <div class="bubble bubble--small placeholder"
                     style="width: 4rem;">&#8203;
                </div>
              </div>
            </div>
          </div>
          `
        }
      )}
    `
  }

  getListUrlLink () {
    if (this.listUrl) {
      return html`
        <div class="button__group button__group--center">
          <a href="${this.listUrl}"
             @click="${(event) => this.sendClickSliderShowAll(event, this.title)}"
             class="button button--filled">
            ${this.listTitle ? this.listTitle : msg(str`show all`)}
          </a>
        </div>
      `
    }
  }

  async fetchOffer () {
    const url = new URL(this.apiUrl, window.location.origin)
    url.searchParams.append('picture_desktop', '3')
    let offers = await fetchJSON(url, {}, [])
    if (Object.prototype.hasOwnProperty.call(offers, 'results')) {
      offers = offers.results.slice(0, 16)
    }
    if (!offers || (offers && offers.length < 4)) {
      this.remove()
      return []
    } else {
      return offers
    }
  }

  sendClickSliderShowAll (event, title) {
    gtag('event', 'click_slider_show_all', {
      slider_show_all_url: event.target.href,
      slider_title: title
    })
  }
}

window.customElements.define('offer-slider', OfferSlider)
