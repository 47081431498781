import Mousetrap from 'mousetrap'
import { ready } from '#js/components/utils'

ready(function () {
  document.querySelectorAll('.mousetrap').forEach(function (element) {
    Object.entries(JSON.parse(element.textContent)).forEach(([key, value]) => {
      Mousetrap.bind(key, () => {
        const rootStyle = document.documentElement.style
        const currentLocation = new URL(window.location.href)

        switch (key) {
          case 'l u x':
          case 'l u m o s':
            rootStyle.setProperty('--dark', '0')
            rootStyle.setProperty('--light', '1')
            break
          case 'n o x':
            rootStyle.setProperty('--dark', '1')
            rootStyle.setProperty('--light', '0')
            break
          case '/':
            document.getElementById(value).focus()
            break
          default:
            if (currentLocation.pathname !== value) {
              window.location.href = value
            }
        }
        return false
      })
    })
  })
})
