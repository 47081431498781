import { LitElement, html, nothing } from 'lit'
import { createRef, ref } from 'lit/directives/ref.js'
import { classMap } from 'lit/directives/class-map.js'

export class CustomDialog extends LitElement {
  static get properties () {
    return {
      position: { type: String },
      dialogId: { type: String }, // Used to open dialog from URL
      hasCloseButton: { type: String },
      canCancel: { type: String }
    }
  }

  connectedCallback () {
    super.connectedCallback()
    this.canCancel = this.canCancel !== 'false'
  }

  disconnectedCallback () {
    this.onCloseModal()
    super.disconnectedCallback()
  }

  /**
   * @type {ref<HTMLDialogElement>}
   */
  dialogRef = createRef()

  firstUpdated () {
    const slotElements = [
      this.shadowRoot.querySelector('slot[name=content]'),
      this.shadowRoot.querySelector('slot[name=dialog-title]')
    ]
    for (const slotElement of slotElements) {
      if (slotElement && slotElement.assignedNodes().length > 0) {
        slotElement.assignedNodes()[0].classList.remove('cloaked')
      }
    }

    if (this.canCancel) {
      this.dialogRef.value.addEventListener('click', event => {
        if (event.target.tagName === 'DIALOG') {
          this.dialogRef.value.close()
        }
      })
    }

    this.dialogRef.value.addEventListener('close', () => {
      this.onCloseModal()
    })

    this.openDialogBasedOnUrlParams()
  }

  openDialogBasedOnUrlParams () {
    if (this.dialogId && new URLSearchParams(window.location.search).get('open-dialog') === this.dialogId) {
      this.showModal()
    }
  }

  showModal () {
    if (this.dialogRef.value && !this.dialogRef.value.open) {
      this.dialogRef.value.showModal()
      this.onModalOpened()
    }
    document.body.style.overflow = 'hidden'
  }

  getCloseButton () {
    if (this.hasCloseButton !== 'false' && this.canCancel) {
      return html`<button class="cross" style="grid-area: close;" @click="${this.close}"></button>`
    }
    return nothing
  }

  close () {
    if (this.dialogRef.value && this.dialogRef.value.open) {
      this.dialogRef.value.close()
    }
    this.onCloseModal()
  }

  onModalOpened () {
    this.dispatchEvent(new CustomEvent('modal-opened', {
      bubbles: true,
      composed: true
    }))
  }

  onCloseModal () {
    document.body.style.overflow = ''
  }

  render () {
    return html`
      <link rel="stylesheet" href="${window.styleFilePath}"/>
      <slot name="trigger" @click="${this.showModal}"></slot>
      <dialog class="dialog ${classMap({ 'dialog--bottom': this.position === 'bottom' })}" ${ref(this.dialogRef)}>
        <div class="card">
          <div class="dialog__head">
            <slot name="dialog-title" style="grid-area: title;"></slot>
            ${this.getCloseButton()}
          </div>
          <slot name="content"></slot>
          <slot name="dialog-button"></slot>
        </div>
      </dialog>
    `
  }
}

window.customElements.define('custom-dialog', CustomDialog)
